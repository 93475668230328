import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../../components/layout"
import SEO from "/src/components/seo"
import VoiceModelImplementation from "../../components/VoiceModelImplementation/VoiceModelImplementation"

const VoiceModelImplementationPage = () => {
    const { t } = useTranslation()
    return (
      <Layout showHeaderBG={true} isShowContactSection={false}>
        <SEO title={t("Voice Model Implementation")} />
        <VoiceModelImplementation/>
      </Layout>
    )
  }

  export default VoiceModelImplementationPage
