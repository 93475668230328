import React from "react"
import './VoiceModelImplementation.scss'


const iconDoc = `${process.env.GATSBY_CDN_CONFIG}/assets/image/icon/icon-doc.png`
const iconEnglandFlag = `${process.env.GATSBY_CDN_CONFIG}/assets/image/icon/icon-eng.png`
const iconFranceFlag = `${process.env.GATSBY_CDN_CONFIG}/assets/image/icon/icon-fra.png`
const iconJapanFlag = `${process.env.GATSBY_CDN_CONFIG}/assets/image/icon/icon-jpn.png`

const audioEn1 = `${process.env.GATSBY_CDN_CONFIG}/assets/audio/sen1_en.wav`
const audioFr1 = `${process.env.GATSBY_CDN_CONFIG}/assets/audio/sen1_fr.mp3`
const audioJp1 = `${process.env.GATSBY_CDN_CONFIG}/assets/audio/sen1_jp.wav`
const audioEn2 = `${process.env.GATSBY_CDN_CONFIG}/assets/audio/sen2_en.wav`
const audioFr2 = `${process.env.GATSBY_CDN_CONFIG}/assets/audio/sen2_fr.wav`
const audioJp2 = `${process.env.GATSBY_CDN_CONFIG}/assets/audio/sen2_jp.wav`
const videoIntro = `${process.env.GATSBY_CDN_CONFIG}/assets/video/voice-model-implementation-2.mp4`

const introBanner = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/VoiceModelImplementation/background-voice-model-intro.jpg`
const implementationBanner = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/VoiceModelImplementation/background-dotted.jpg`

const VoiceModelImplementation = () => (
    <div className="product-page">
        <section className="gradient-banner" style={{backgroundImage: `url(${introBanner})`}}>
            <div className="wrapper align-items-start">
                <h1>Harness the Power of <span className="--high-lighted">AI Speech Models</span></h1>
                <p className="mt-4 mb-5">Elevating human-computer interaction to unprecedented levels of <span className="d-inline d-sm-block">accuracy and naturalness using high-quality Text-to-Speech and Speech-to-Text models.</span></p>
                <a className="button button--primary-blue" href="/contact/"><i className="icon icon--phone"></i> Schedule a Call</a>
            </div>
        </section>

        <section className="wrapper --spacer">
            <h2 className="mb-4">Leverage Text-to-Speech and Speech-to-Text Models</h2>
            <p className="text-center mb-4">Developing high-quality voice transcription and text conversion systems requires expertise and a meticulous approach.<br/>
            Our experienced team, with precision in mind, can utilize Text-to-Speech and Speech-to-Text models to create tailor-made solutions that enhance communications with exceptional accuracy.</p>

            <div className="row justify-content-lg-between justify-content-around">
                    <div className="info-box mt-4">
                        <h3>Whisper</h3>
                        <p>Understand nuances, accents, and terminologies across multiple domains and transcribe them accurately.</p>
                    </div>
                    <div className="info-box mt-4">
                        <h3>Fast Whisper</h3>
                        <p>Offer a rapid conversion, making it ideal for time-sensitive applications without sacrificing quality.</p>
                    </div>
                    <div className="info-box mt-4">
                        <h3>Instant-Fast-Whisper</h3>
                        <p>Give instant and real-time responses to hours-long audio or videos within minutes.</p>
                    </div>
                    <div className="info-box mt-4">
                        <h3>Bark</h3>
                        <p>Produce human-like speech from vast amounts of text with remarkable naturalness.</p>
                    </div>
                    <div className="info-box mt-4">
                        <h3>Seamless Streaming</h3>
                        <p>Provide a constant speech flow without interruption or delay, enhancing user satisfaction.</p>
                    </div>
                    <div className="info-box mt-4">
                        <h3>FastSpeech 2</h3>
                        <p>Top-tier text-to-speech model to synthesize speech faster with smoother and more human-like output.</p>
                    </div>
            </div>
        </section>

        <section className="wrapper --spacer pb-5 px-0">
            <h2 className="mb-4">From Technologies to Practical Uses</h2>
            <p className="text-center mb-5">Experience the future of communication. Let your users enjoy a modernized, user-friendly, <span className="d-md-inline-block">hands-free writing experience and seamless interaction with digital devices.</span></p>

            <div className="row align-items-center">
                <div className="col-xl-5 col-md-6 col-12 pr-md-4">
                    <h3 className="--big">Transforming Voice into Words</h3>
                    <ul className="list-style">
                        <li><strong>Voice a ssistants:</strong> Increase convenience by performing tasks or services based on user’s voice commands.</li>
                        <li><strong>Transcription services:</strong> Enhance communication accessibility with real-time captions for live events, meetings, or broadcasts.</li>
                        <li><strong>Dictation software:</strong> Maximize productivity and convenience, offering a hands-free alternative to traditional typing.</li>
                    </ul>
                </div>
                <div className="col-xl-7 col-md-6 col-12 pl-md-3">
                    <div className="info-box --full-width text-left">
                        <div className="row align-items-center d-none d-sm-flex">
                            <div className="col-sm-6 col-12 pr-sm-4">
                                <p className="text-with-icon pl-5" style={{backgroundImage: `url(${iconDoc})`}}>GREAT! I just received a job offer from Neurond AI company as an AI Engineer.</p>
                            </div>
                            <div className="col-sm-6 col-12 pl-sm-4">
                                <p className="text-with-icon pl-5" style={{backgroundImage: `url(${iconDoc})`}}>Oh no, it's been 15 minutes, and the bus still hasn't arrived. I'm about to be late for the interview at Neurond.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-12 pr-sm-4">
                                <p className="text-with-icon pl-5 d-sm-none" style={{backgroundImage: `url(${iconDoc})`}}>GREAT! I just received a job offer from Neurond AI company as an AI Engineer.</p>

                                <p className="text-with-icon mt-4" style={{backgroundImage: `url(${iconEnglandFlag})`}}>
                                    <audio className="audio-style" controls preload="metadata">
                                        <source src={audioEn1} type="audio/wav"/>
                                        Your browser does not support the audio element.
                                    </audio>
                                </p>
                                <p className="text-with-icon mt-4" style={{backgroundImage: `url(${iconFranceFlag})`}}>
                                    <audio className="audio-style" controls preload="metadata">
                                        <source src={audioFr1} type="audio/mp3"/>
                                        Your browser does not support the audio element.
                                    </audio>
                                </p>
                                <p className="text-with-icon mt-4" style={{backgroundImage: `url(${iconJapanFlag})`}}>
                                    <audio className="audio-style" controls preload="metadata">
                                        <source src={audioJp1} type="audio/wav"/>
                                        Your browser does not support the audio element.
                                    </audio>
                                </p>
                            </div>
                            <div className="col-sm-6 col-12 pl-sm-4 mt-5 mt-sm-0">
                                <p className="text-with-icon pl-5 d-sm-none" style={{backgroundImage: `url(${iconDoc})`}}>Oh no, it's been 15 minutes, and the bus still hasn't arrived. I'm about to be late for the interview at Neurond.</p>

                                <p className="text-with-icon mt-4" style={{backgroundImage: `url(${iconEnglandFlag})`}}>
                                    <audio className="audio-style" controls preload="metadata">
                                        <source src={audioEn2} type="audio/wav"/>
                                        Your browser does not support the audio element.
                                    </audio>
                                </p>
                                <p className="text-with-icon mt-4" style={{backgroundImage: `url(${iconFranceFlag})`}}>
                                    <audio className="audio-style" controls preload="metadata">
                                        <source src={audioFr2} type="audio/wav"/>
                                        Your browser does not support the audio element.
                                    </audio>
                                </p>
                                <p className="text-with-icon mt-4" style={{backgroundImage: `url(${iconJapanFlag})`}}>
                                    <audio className="audio-style" controls preload="metadata">
                                        <source src={audioJp2} type="audio/wav"/>
                                        Your browser does not support the audio element.
                                    </audio>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper --spacer px-0">
                <div className="row align-items-center">
                    <div className="col-md-6 col-12 pr-md-4">
                    <video className="video-style" controls preload="metadata">
                        <source src={videoIntro} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    </div>
                    <div className="col-md-6 col-12 pl-md-3">
                        <h3 className="--big">Converting Text into Speech</h3>
                        <ul className="list-style">
                            <li><strong>GPS systems:</strong> Drive safer with audio-enabled GPS that provides spoken directions to reach destinations without glancing at the device.</li>
                            <li><strong>Public announcements:</strong> Improve public information broadcasting with verbal delivery at airports or railway stations.</li>
                            <li><strong>Telecommunication:</strong> Elevate the calling experience by reading out text messages or providing caller information.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className="gradient-banner --even-padding mt-5" style={{backgroundImage: `url(${implementationBanner})`}}>
            <div className="wrapper">
                <h2 className="mb-4">Simple Yet Streamlined TTS and STT Model Implementation</h2>
                <div className="row justify-content-lg-between justify-content-around w-100">
                    <div className="info-box --white-background --narrow dotted-spacer mt-4">
                        <h3>Customization</h3>
                        <p>Your business is unique, and your voice systems should be too. We craft distinctive solutions that fit your requirements and complement your vision.</p>
                    </div>
                    <div className="info-box --white-background --narrow dotted-spacer mt-4">
                        <h3>Scalability</h3>
                        <p>Your user base may grow exponentially. Our solutions scale with you, maintaining performance and reliability.</p>
                    </div>
                    <div className="info-box --white-background --narrow mt-4">
                        <h3>Integration</h3>
                        <p>We make it seamless, whether that's through APIs, on mobile platforms, or within web applications.</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="wrapper --spacer mb-5">
            <h2 className="mb-4">Stay Ahead of the Curve with Us</h2>
            <p className="text-center mb-5">Partner with us to bring the cutting edge of voice technology to your products.</p>
        </section>
    </div>
)

export default VoiceModelImplementation
